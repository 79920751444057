import { Image } from '@graphcommerce/image'
import { Footer as FooterBase, IconSvg, iconLocation, useFabSize } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Button, GlobalStyles, IconButton, Link, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { FooterQueryFragment } from './FooterQueryFragment.gql'

export type FooterProps = FooterQueryFragment

export function Footer(props: FooterProps) {
  const { footer } = props
  const globalTheme = useTheme()
  const fabIconSize = useFabSize('responsive')

  const weplyChatWidgetGlobalStyles = (
    <GlobalStyles
      styles={{
        '& body iframe[title="Weply chat"]': {
          [globalTheme.breakpoints.down('md')]: {
            bottom: `calc(20px + (${fabIconSize} + ${globalTheme.spacings.xxs})) !important`,
          },
        },
      }}
    />
  )

  return (
    <>
      {weplyChatWidgetGlobalStyles}
      <FooterBase
        sx={{
          backgroundColor: 'background.dark',
        }}
        socialLinks={footer?.socialLinks?.map((link) => (
          <IconButton key={link.title} href={link.url} color='inherit' size='medium' edge='start'>
            {link.asset ? (
              <Image
                layout='fill'
                src={link.asset.url}
                width={24}
                height={24}
                unoptimized
                alt={link.title}
                sx={{
                  filter: 'invert(100%)',
                }}
              />
            ) : (
              link.title
            )}
          </IconButton>
        ))}
        storeSwitcher={
          <Button
            href='/partners'
            variant='pill'
            color='primary'
            size='medium'
            sx={(theme) => ({
              ...theme.typography.h6,
            })}
            startIcon={<IconSvg key='icon' src={iconLocation} size='inherit' />}
          >
            <Trans id='Fietsenwinkels' />
          </Button>
        }
        customerService={
          <Button
            href='/service'
            variant='pill'
            color='inherit'
            sx={(theme) => ({
              ...theme.typography.h6,
            })}
          >
            <Trans id='Customer Service' />
          </Button>
        }
        copyright={
          <>
            <Typography component='span' variant='body1' sx={{ color: 'primary.contrastText' }}>
              {footer?.copyright}
            </Typography>
            {footer?.legalLinks?.map((link) => (
              <Link
                key={link.title}
                href={link.url}
                variant='body1'
                sx={{ color: 'primary.contrastText' }}
                underline='hover'
              >
                {link.title}
              </Link>
            ))}
          </>
        }
      />
    </>
  )
}
