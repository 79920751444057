import { Box } from '@mui/material'
import { Inline } from '../GraphCMS/RowLinks/variant'
import { FooterLinksListsQueryFragment } from './FooterLinksListsQueryFragment.gql'

export function FooterLinks(props: FooterLinksListsQueryFragment) {
  const { footerLinksLists } = props

  if (!footerLinksLists) return null

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: 'background.shade',
        py: theme.spacings.lg,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacings.sm,

        '& .RowLinks-root': { my: 0, color: 'primary.contrastText' },
        '& .RowLinks-root a': { color: 'text.secondary', ...theme.typography.body1 },
        '& .RowLinks-title': { fontWeight: 600 },
        '& .RowLinks-centerLeft, & .RowLinks-centerRight': { display: 'none' },
      })}
    >
      {footerLinksLists.map((list) => (
        <Inline key={list.identity} {...list} />
      ))}
    </Box>
  )
}
